import { ChangeEvent, memo, useEffect, useRef, useState } from "react";
import styles from "./InputWithOptions.module.scss";
import { withFormFeedback } from "../FormFeedback/FormFeedback";

export type InputWithOptionsProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value' | 'defaultValue'> & {
    invalid?: boolean;
    innerRef?: React.Ref<HTMLInputElement>;
    validationErrors?: string[];
    options: string[];
    defaultValue?: string;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
};

export const InputWithOptions = memo((props: InputWithOptionsProps) => {
    const {
        innerRef,
        className,
        invalid,
        validationErrors,
        style,
        options,
        defaultValue = '',
        onChange: propOnChange,
        ...rest
    } = props;

    const [filteredOptions, setFilteredOptions] = useState<string[]>([]);
    const [internalValue, setInternalValue] = useState<string>(defaultValue);
    const [showOptions, setShowOptions] = useState<boolean>(false);
    const wrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setInternalValue(defaultValue);
    }, [defaultValue]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside, { capture: true });
        return () => {
            document.removeEventListener('mousedown', handleClickOutside, { capture: true });
        };
    }, []);

    const handleClickOutside = (event: MouseEvent) => {
        if (
            wrapperRef.current &&
            !wrapperRef.current.contains(event.target as Node)
        ) {
            setShowOptions(false);
        }
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
        setInternalValue(inputValue);
        if (propOnChange) {
            propOnChange(e);
        }
        if (inputValue) {
            const filtered = options.filter((option) =>
                option.toLowerCase().includes(inputValue.toLowerCase())
            );
            setFilteredOptions(filtered);
            setShowOptions(true);
        } else {
            setFilteredOptions(options);
            setShowOptions(true);
        }
    };

    const handleSelect = (option: string) => {
        setInternalValue(option);
        if (propOnChange) {
            const event = {
                target: { value: option },
            } as ChangeEvent<HTMLInputElement>;
            propOnChange(event);
        }
        setShowOptions(false);
    };

    const handleClick = () => {
        const trimmedValue = internalValue?.trim();
        const filtered = trimmedValue
            ? options.filter((option) =>
                  option.toLowerCase().includes(trimmedValue.toLowerCase())
              )
            : options;
        setFilteredOptions(filtered);
        setShowOptions(true);
    };

    const cls = ['input-wrapper'];

    if (className) cls.push(className);
    if (invalid || validationErrors?.length) cls.push('is-invalid');

    return (
        <div ref={wrapperRef} style={{ position: 'relative', ...style }}>
            <input
                type="text"
                defaultValue={defaultValue}
                value={internalValue}
                onChange={handleChange}
                onClick={handleClick}
                className={cls.join(' ')}
                ref={innerRef}
                {...rest}
            />
            {showOptions && filteredOptions.length > 0 && (
                <ul className={styles.optionsList}>
                    {filteredOptions.map((option) => (
                        <li
                            key={option}
                            onClick={() => handleSelect(option)}
                            onMouseDown={(e) => e.preventDefault()}
                            className={styles.optionItem}
                        >
                            {option}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
});


export const InputWithOptionsAndFeedback = withFormFeedback<InputWithOptionsProps>(InputWithOptions);
