import styles from './Menu.module.scss';
import { observer } from 'mobx-react';

import { Link } from '@Components/Link/Link';
import { authStore } from "@GlobalStores";
import { useLocation } from 'react-router-dom';

function Menu() {
    const location = useLocation();
    const { canPrincipalSeeProjects, canPrincipalSeeMatrices, canPrincipalSeeAdministration,
        canPrincipalSeeToolPanel, canPrincipalSeeTestReportPart, canPrincipalSeeSustainability,
        canPrincipalSeeStlGuideline, canPrincipalSeeScMembers
    } = authStore.appSecurityModel;

    const href = '#';

    return (
        <div className={styles.container}>
            {renderLink('/', 'Dashboard')}
            {canPrincipalSeeProjects && renderLink('/projects', 'Projects')}
            {canPrincipalSeeMatrices && renderLink('/type-test-matrices', 'Matrices')}
            {canPrincipalSeeAdministration && renderLink('/administration', 'TMS Data')}
            {canPrincipalSeeToolPanel && (
                <div className={styles.dropdown}>
                    <a href={href} className={styles.item} target="_self" rel="noreferrer">
                        Tools
                    </a>
                    <div className={styles.dropdownContent}>
                        <div className={styles.dropdownWrap}>
                            {
                                renderLink('/healthboard', 'Healthboard')
                            }
                            {
                                renderLink('/job-list', 'Jobs')
                            }
                            {
                                renderLink('/configuration', 'Configuration')
                            }
                            {
                                renderLink('/user-access-management', 'User Access Management')
                            }
                        </div>
                    </div>
                </div>
            )}
            {/*{canPrincipalSeeLabsPart && renderLink('/laboratory', 'Laboratory')}*/}
            {canPrincipalSeeTestReportPart && renderLink('/test-report-view', 'Test Reports (TRD)')}
            {canPrincipalSeeSustainability && renderLink('/sustainability', 'Sustainability')}
            {canPrincipalSeeStlGuideline && renderLink('/stl-guideline', 'STL Guideline')}
            {canPrincipalSeeScMembers && renderLink('/sc-members', 'SC Members')}
        </div>
    );


    function renderLink(path: string, title: string) {

        let isActive = false;

        if (location.pathname === path) {
            isActive = true;
        }

        if (!isActive && path === '/administration' && location.pathname.includes(path)) {
            isActive = true;
        }

        if (!isActive && path === '/projects' && location.pathname.startsWith('/project')) {
            isActive = true;
        }

        if (!isActive && path === '/type-test-matrices' && location.pathname.startsWith('/type-test-matrices')) {
            isActive = true;
        }

        //if (!isActive && path === '/laboratory' && location.pathname.startsWith('/laboratory')) {
        //    isActive = true;
        //}

        if (!isActive && path === '/test-report-view' && location.pathname.startsWith('/test-report-view')) {
            isActive = true;
        }

        if (!isActive && path === '/sustainability' && location.pathname.startsWith('/sustainability')) {
            isActive = true;
        }

        if (!isActive && path === '/stl-guideline' && location.pathname.startsWith('/stl-guideline')) {
            isActive = true;
        }

        if (!isActive && path === '/sc-members' && location.pathname.startsWith('/sc-members')) {
            isActive = true;
        }

        const cls = [styles.item];
        isActive && cls.push(styles.activeLink)
        return (
            <Link to={path} className={cls.join(' ')}>
                {title}
            </Link>
        );
    }
}
const MenuObserver = observer(Menu);
export {MenuObserver as Menu };
