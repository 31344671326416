import * as React from 'react';
import DateTimeField from '@1stquad/react-bootstrap-datetimepicker';
import { ImCross } from 'react-icons/im';

import {DateTime} from '@AppConstants';
import {DateTimeService} from '@Services';

import './date-picker.scss';

export type DatePickerProps = {
    wrapperClassName?: string;
    disabled?: boolean;
    invalid?: boolean;
    showToday?: boolean;

    minDate?: Date;
    maxDate?: Date;
    value?: Date;
    availableDates?: Date[];

    viewMode?: string;
    pickerMode?: string;
    pickerSize?: string;

    daysOfWeekDisabled?: number[];
    allDaysDisabled?: boolean;

    onBlur?: Function;
    onEnter?: Function;
    onChange?: (date: Date, value: string) => void;
    onClear?: () => void;
};

export class DatePicker extends React.Component<DatePickerProps, {}> {
    constructor(props: DatePickerProps) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    render() {
        const {disabled, minDate, maxDate, onBlur, onEnter, pickerMode, pickerSize, daysOfWeekDisabled, viewMode, availableDates} = this.props;
        const {value, wrapperClassName, showToday, invalid} = this.props;
        const cls: string[] = [];
        const minimalDate = minDate && DateTimeService.isValidDate(minDate) ? minDate : DateTimeService.parse(DateTime.minDate, DateTime.viewDateFormat);
        const maximalDate = maxDate && DateTimeService.isValidDate(maxDate) ? maxDate : undefined;

        const format = this._getPickerFormat(pickerMode);
        const finalValue = value
            ? DateTimeService.parse(DateTimeService.format(value, format, format !== DateTime.viewDateFormat), format, format !== DateTime.viewDateFormat)
            : '';

        wrapperClassName && cls.push(wrapperClassName);
        // TODO implement prop(invalid) logic / styles
        // const validatedClass = formModel.formValidatedClass;
        const validatedClass = 'skin';
        validatedClass && cls.push(validatedClass);

        // horrible workaround
        const validationClass = invalid ? 'is-invalid' : '';
            
        if (showToday === false) {
            cls.push('show-today-disabled');
        }
        return (
            <div className={cls.join(' ')}>
                <DateTimeField
                    dateTime={finalValue}
                    hideInputIcon
                    showToday={showToday}
                    inputFormat={format}
                    onChange={this.handleChange}
                    onBlur={onBlur ? onBlur : undefined}
                    onEnterKeyDown={onEnter ? onEnter : undefined}
                    mode={pickerMode ? pickerMode : 'date'}
                    viewMode={viewMode ? viewMode : 'date'}
                    size={pickerSize ? pickerSize : 'sm'}
                    minDate={minimalDate}
                    maxDate={maximalDate}
                    disabled={disabled}
                    daysOfWeekDisabled={daysOfWeekDisabled ? daysOfWeekDisabled : []}
                    validationClass={validationClass}
                    availableDates={availableDates}
                />
                {!disabled && this.props.onClear && value && <div className="cross-wrapper" onClick={this.props.onClear}>
                    <ImCross className='date-picker-cross' /> 
                </div>}
            </div>
        );
    }

    handleChange(date: Date, value: string) {
        // console.log(value);
        const d = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()))
        // const d = DateTimeService.parseUiDate(value);
        this.props.onChange?.(d, value);
    }

    private _getPickerFormat(mode?: string): string {
        if (mode === 'datetime') {
            return DateTime.viewFullFormat;
        }
        if (mode === 'time') {
            return DateTime.timeFormat;
        }
        if (mode === 'month') {
            return DateTime.monthPeriodFullFormat;
        }
        return DateTime.viewDateFormat;
    }
}