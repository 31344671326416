export class PrincipalConfig {
    public static readonly GridStateKey: string = "GridState";
    public static readonly GridFilterKey: string = "GridFilter";

    public static readonly Standards: string = "Standards";
    public static readonly Products: string = "Products";
    public static readonly ProductCategories: string = "ProductCategories";
    public static readonly TechnologyCenter: string = "TechnologyCenters";
    public static readonly Laboratories: string = "Laboratories";
    public static readonly TechnicalRatings: string = "TechnicalRatings";
    public static readonly DesignParameters: string = "DesignParameters";
    public static readonly Projects: string = "Projects";
    public static readonly TypeTestPlans: string = "TypeTestPlans";
    public static readonly TypeTestMatrix: string = "TypeTestMatrix";
    public static readonly TypeTestPlanApprovals: string = "TypeTestPlanApprovals";
    public static readonly ProjectTeamMembers: string = "ProjectTeamMembers";
    public static readonly Tests: string = "Tests";
    public static readonly TestObjects: string = "TestObjects";
    public static readonly ProductVariantsInfo: string = "ProductVariantsInfo";
    public static readonly LabOfferRequests: string = "LabOfferRequests";
    public static readonly LabOfferRequestsLaboratoryView: string = "LabOfferRequestsLaboratoryView";
    public static readonly TypeTestMatrices: string = "TypeTestMatrices";
    public static readonly TestReportView: string = "TestReportView";
    public static readonly DeclarationOfConformity: string = "DeclarationOfConformity";
    public static readonly PlanTestReports: string = "PlanTestReports";
    public static readonly CellStateKey: string = "CellStateKey";
    public static readonly Components: string = "Components";
    public static readonly AvailableForReferenceTests: string = "AvailableForReferenceTests";
    public static readonly ReferenceThisTestTable: string = "ReferenceThisTestTable";
    public static readonly ReferenceThisTestTestReportsTable: string = "ReferenceThisTestTestReportsTable";
    public static readonly TrdDocTestReports: string = "TrdDocTestReports";
    public static readonly DocTestReports: string = "DocTestReports";
    public static readonly SProductFamilyVariants: string = "SProductFamilyVariants";
    public static readonly StlGuidelineDocumentsView: string = "StlGuidelineDocumentsView";
    public static readonly ScMembersGrid: string = "ScMembersGrid";
}